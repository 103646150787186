/**
 * @program: oa2.0
 * @author: ly
 * @description: HumanResourcesComp
 * @create: 2023-08-25 09:34
 **/



let HumanResourcesComp = [
    {
        path: '',
        component: () => import("@/page/Other/OAMenuDefaultPage/MenuList"),
        name: "HumanResourcesComp",
        children: () => {
            return HumanResourcesComp
        }
    },
    {
        path: 'UserInfoManager',
        component: () => import("@/page/HumanResources/UserInfoManager/UserInfoManager"),
        name: "UserInfoManager",
        cnName: "用户管理"
    },
    {
        path: 'DepartmentManager',
        component: () => import("@/page/HumanResources/DepartmentManager/DepartmentManager"),
        name: "DepartmentManager",
        cnName: "部门设定"
    },
    {
        path: 'phoneBook',
        component: () => import("@/page/PersonalCenter/MyInfo/phoneBook"),
        name: "phoneBook",
        cnName: "通讯录"
    },


    {
        path: 'PromotionInfo',
        component: () => import("@/page/HumanResources/StaffPromotion/StaffPromotionSetting/PromotionInfo"),
        name: "PromotionInfo",
        cnName: "晋升信息"
    },
    {
        path: 'PromotionPoints',
        component: () => import("@/page/HumanResources/StaffPromotion/StaffPromotionSetting/PromotionPoints"),
        name: "PromotionPoints",
        cnName: "晋升评分表"
    },
    {
        path: 'StaffPromotionAudit',
        component: () => import("@/page/HumanResources/StaffPromotion/StaffPromotionSetting/StaffPromotionAudit"),
        name: "StaffPromotionAudit",
        cnName: "员工晋升-审核"
    },
    {
        path: 'StaffPromotionSetting',
        component: () => import("@/page/HumanResources/StaffPromotion/StaffPromotionSetting/StaffPromotionSetting"),
        name: "StaffPromotionSetting",
        cnName: "员工晋升-考核科目配置"
    },
    {
        path: 'UseSealSetting',
        component: () => import("@/page/HumanResources/SealManager/UseSealSetting"),
        name: "UseSealSetting",
        cnName: "印章用途设定"
    },

    {
        path: 'MonthlyAssessment',
        component: () => import("@/page/HumanResources/StaffAssessment/MonthlyAssessment/MonthlyAssessment"),
        name: "MonthlyAssessment",
        cnName: "员工考核-月度考核"
    },
    {
        path: 'UserKPI',
        component: () => import("@/page/HumanResources/StaffAssessment/MonthlyAssessment/UserKPI"),
        name: "UserKPI",
        cnName: "员工月度KPI"
    },
    {
        path: 'PositionAimSetting',
        component: () => import("@/page/HumanResources/StaffAssessment/MonthlyAssessment/PositionAimSetting"),
        name: "PositionAimSetting",
        cnName: "员工考核-职位月度绩效目标配置"
    },
    {
        path: 'MonthlyAssessmentSetting',
        component: () => import("@/page/HumanResources/StaffAssessment/MonthlyAssessment/MonthlyAssessmentSetting"),
        name: "MonthlyAssessmentSetting",
        cnName: "员工考核-月度考核科目配置"
    },
    {
        path: 'AssessmentRoleSetting',
        component: () => import("@/page/HumanResources/StaffAssessment/MonthlyAssessment/AssessmentRoleSetting"),
        name: "AssessmentRoleSetting",
        cnName: "员工考核-月度考核评分角色设置"
    },
    {
        path: 'YearlyAssessment',
        component: () => import("@/page/HumanResources/StaffAssessment/YearlyAssessment/YearlyAssessment"),
        name: "YearlyAssessment",
        cnName: "员工考核-年度考核"
    },
    {
        path: 'DepartmentAimSetting',
        component: () => import("@/page/HumanResources/StaffAssessment/YearlyAssessment/DepartmentAimSetting"),
        name: "DepartmentAimSetting",
        cnName: "员工考核-生产部门年度绩效目标配置"
    },
    {
        path: 'YearlyAssessmentSetting',
        component: () => import("@/page/HumanResources/StaffAssessment/YearlyAssessment/YearlyAssessmentSetting"),
        name: "YearlyAssessmentSetting",
        cnName: "员工考核-年度考核科目配置"
    },
    {
        path: 'AttendanceRecords',
        component: () => import("@/page/HumanResources/StaffAssessment/AttendanceRecords/AttendanceRecords"),
        name: "AttendanceRecords",
        cnName: "员工考核-考勤记录"
    },
    {
        path: 'AttendanceRuleSetting',
        component: () => import("@/page/HumanResources/StaffAssessment/AttendanceRecords/AttendanceRuleSetting"),
        name: "AttendanceRuleSetting",
        cnName: "员工考核-考勤扣分规则"
    },
    {
        path: 'HygieneRecords',
        component: () => import("@/page/HumanResources/StaffAssessment/HygieneRecords/HygieneRecords"),
        name: "HygieneRecords",
        cnName: "员工考核-卫生记录"
    },
    {
        path: 'UserExtraManager',
        component: () => import("@/page/HumanResources/UserExtraManager/UserExtraManager"),
        name: "UserExtraManager",
        cnName: "简历模块"
    },
    {
        path: 'CooperationUnits',
        component: () => import("@/page/HumanResources/CooperationUnits/CooperationUnits"),
        name: "CooperationUnits",
        cnName: "合作单位及人员"
    },

]

export default HumanResourcesComp

