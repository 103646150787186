/**
 * @program: oa2.0
 * @author: ly
 * @description: WorkAdministrationComp
 * @create: 2023-08-25 09:34
 **/


let WorkAdministrationComp = [
    {path: '',name:"WorkAdministrationComp", component: ()=>import("@/page/Other/OAMenuDefaultPage/MenuList") , children:()=>{return WorkAdministrationComp}},
    {path: 'ProjectManager', name:"ProjectManager",cnName:"项目管理", component: ()=>import("@/page/WorkAdministration/ProjectManager/ProjectManager")},
    {path: 'ContractTrace', name:"ContractTrace",cnName:"合同追踪", component: ()=>import("@/page/WorkAdministration/ContractTrace/ContractTrace")},
    {path: 'AbandonedProject',name:"AbandonedProject",cnName:"虚拟项目管理",
        component: ()=>import("@/page/WorkAdministration/ProjectManager/AbandonedProject")
    },
    {path: 'QCManager',name:"QCManager",cnName:"质检管理",
        component: ()=>import("@/page/WorkAdministration/QCManager/QCManager"),
        children:[
            {path: 'QCApply',name:"QCApply",cnName:"质检申请审核",
                component: ()=>import("@/page/WorkAdministration/QCManager/QCApply"),
                meta:{content:"指定负责人",checkAuth:"parentAuth"}
            },
            {path: 'QCHistory',name:"QCHistory",cnName:"质检记录一览",
                component: ()=>import("@/page/WorkAdministration/QCManager/QCHistory"),
                meta:{content:"",checkAuth:"parentAuth"}
            },
            {path: 'QCFileAudit',name:"QCFileAudit",cnName:"项目质检文件审核",
                component: ()=>import("@/page/WorkAdministration/QCManager/QCFileAudit"),
                meta:{content:"",checkAuth:"parentAuth"}
            },
        ]
    },
    {path: 'ProjectTaskArrange',name:"ProjectTaskArrange",cnName:"项目任务管理", component: ()=>import("@/page/WorkAdministration/ProjectTask/ProjectTaskArrange")},
    {path: 'DepartmentTaskViewer', name:"DepartmentTaskViewer",cnName:"部门项目任务管理",component: ()=>import("@/page/WorkAdministration/Department/DepartmentTaskViewer")},
    {path: 'PoolManager',name:"PoolManager",cnName:"绩效池管理", component: ()=>import("@/page/WorkAdministration/Pool/PoolManager")},
    {path: 'UserPerformanceMange',name:"UserPerformanceMange",cnName:"个人绩效管理", component: ()=>import("@/page/WorkAdministration/Pool/UserPerformanceMange")},
    {path: 'DepartmentPool', name:"DepartmentPool",cnName:"部门绩效池管理", component: ()=>import("@/page/WorkAdministration/Department/DepartmentPool/DepartmentPool")},

    {path: 'OAInfoManager',  name:"OAInfoManager",cnName:"公告管理",component: ()=>import("@/page/System/OAInfoManager/OAInfoManager")},
    {path: 'DepartmentWork',
        name:"DepartmentWork",
        cnName:"部门工作汇总",
        component: ()=>import("@/page/WorkAdministration/Department/DepartmentWork/DepartmentWork"),
        children:[
            {path: 'Work1', component: ()=>import("@/page/WorkAdministration/Department/DepartmentTaskViewer"),
                name:"DepartmentWorkWork1",cnName:"部门任务一览",
                meta:{content:"指定负责人，提交完成，延期申请",checkAuth:"parentAuth"}
            },
            {path: 'Work2', component: ()=>import("@/page/WorkAdministration/Department/DepartmentWork/DepartmentWeekReport"),
                name:"DepartmentWorkWork2",cnName:"周报审核",
                meta:{content:"审核部门周报及提交总经办",checkAuth:"parentAuth"}
            },
            {path: 'Work3', component: ()=>import("@/page/WorkAdministration/Department/DepartmentPool/DepartmentPool"),
                name:"DepartmentWorkWork3",cnName:"部门绩效池",
                meta:{content:"部门绩效总览，支付其他部门",checkAuth:"parentAuth"},
            },
            {path: 'DepartmentPerformance', component: ()=>import("@/page/WorkAdministration/Department/DepartmentPool/DepartmentPerformance"),
                name:"DepartmentPerformance",cnName:"成员年度绩效",
                meta:{content:"部门成员年度绩效一览",checkAuth:"parentAuth"},
            },
            {path: 'Work8', component: ()=>import("@/page/WorkAdministration/Department/DepartmentWork/DepartmentProjectFlows"),
                name:"DepartmentWorkWork8",cnName:"项目收付款",
                meta:{content:"外协支付、项目收款",checkAuth:"parentAuth"},
            },
            {path: 'Work4', component: ()=>import("@/page/WorkAdministration/Department/DepartmentWork/DepartmentMemberKPI"),
                name:"DepartmentWorkWork4",cnName:"部门成员KPI",
                meta:{content:"月度绩效统计",checkAuth:"parentAuth"}
            },
            {path: 'Work9', component: ()=>import("@/page/WorkAdministration/Department/DepartmentWork/DepartmentAbandonedProject"),
                name:"DepartmentAbandonedProject",cnName:"虚拟项目管理",
                meta:{content:"",checkAuth:"parentAuth"},
            },
            {path: 'Work5', component: ()=>import("@/page/WorkAdministration/ProjectRegister/ProjectRegister"),
                name:"DepartmentWorkWork5",cnName:"项目创建",
                meta:{content:"新项目创建，请注意不要重复创建",checkAuth:"parentAuth"},
            },
            {path: 'Work10', component: ()=>import("@/page/WorkAdministration/Department/DepartmentAssetsBuy"),
                name:"DepartmentAssetsBuy",cnName:"设备采买申请",
                meta:{content:"设备采买",checkAuth:"parentAuth"},
            },
            {path: 'Work11', component: ()=>import("@/page/WorkAdministration/Department/DepartmentMaterialBuy"),
                name:"DepartmentMaterialBuy",cnName:"耗材申请",
                meta:{content:"设备采买",checkAuth:"parentAuth"},
            },
            {path: 'Work6', component: ()=>import("@/page/WorkAdministration/Department/DepartmentWork/DepartmentMember"),
                name:"DepartmentWork6",cnName:"部门成员一览",
                meta:{content:"工作安排，绩效，工作日志",checkAuth:"parentAuth"},
            },
            {path: 'DepartmentAssets', component: ()=>import("@/page/WorkAdministration/Department/DepartmentAssets"),
                name:"DepartmentAssets",cnName:"部门设备一览",
                meta:{content:"所有部门成员设备",checkAuth:"parentAuth"},
            },
            {path: 'ProjectGroupView', component: ()=>import("@/page/WorkAdministration/Department/DepartmentWork/ProjectGroupView"),
                name:"ProjectGroupView",cnName:"所有项目一览",
                meta:{content:"",checkAuth:"parentAuth"},
            },

            // {path: 'Work7', component: ()=>import("@/page/WorkAdministration/Department/DepartmentWork/DepartmentRole"),
            //     name:"DepartmentWork7",cnName:"部门角色管理",
            //     meta:{content:"正在开发",checkAuth:"parentAuth"},
            // },
        ]
    },
    {path: 'ProjectWeekReport',name:"ProjectWeekReport",cnName:"项目周报审核", component: ()=>import("@/page/WorkAdministration/ProjectWeekReport/ProjectWeekReport")},
    {path: 'ProjectProcessViewer',name:"ProjectProcessViewer",cnName:"项目进度一览",component: ()=>import("@/page/WorkAdministration/ProjectProcessViewer/ProjectProcessViewer")},
    {path: 'ProjectRegister',  name:"ProjectRegister",cnName:"项目创建",component: ()=>import("@/page/WorkAdministration/ProjectRegister/ProjectRegister")},
    {path: 'ProjectAudit',name:"ProjectAudit",cnName:"项目审核", component: ()=>import("@/page/WorkAdministration/ProjectAudit/ProjectAudit")},
    {path: 'DelayManager', name:"DelayManager",cnName:"项目延期审核",component: ()=>import("@/page/WorkAdministration/DelayManager/DelayManager")},
]

export default WorkAdministrationComp